import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/recipe1.css'

import $ from 'jquery' // important: case sensitive.
import cardfoto from "../images/carne2.png" // Tell webpack this JS file uses this image
import recipeicon from "../images/recipe-icon.png" // Tell webpack this JS file uses this image

import Layout from "../components/layout"
import SEO from "../components/seo"
import cardfoto1 from "../images/BacalhauaBraz_baixa.jpg" // Tell webpack this JS file uses this image
import cardfoto2 from "../images/BacalhauLegumes_baixa.jpg" // Tell webpack this JS file uses this image
import cardfoto3 from "../images/BacalhaucomGrao_baixa.jpg" // Tell webpack this JS file uses this image
import iconnutri from "../images/icon-nutri.png" // Tell webpack this JS file uses this image



const ReceitaA = () => (
  <Layout>
    <SEO title="Bacalhau Mil e Uma Maneiras - Receita 1" />

    <div id="rec-section-1">
        <div className="wrapper-foto">
    </div>

        <div className="wrapper">
        <div className="titulo playfair-black">
            Bacalhau à Brás
        </div>
        <div className="desc sneak-regular">
        Uma refeição rápida sem prescindir do sabor? O bacalhau à Brás é a união perfeita do prático e do tradicional.
                </div>
        </div>
    </div>
    <div id="rec-section-2">
        <div className="wrapper">
            <div className="left-col">
                <div className="card"> 
                <div className="contentor">
                    <div className="foto">
                    {/* <img src={cardfoto} alt="Logo" /> */}

                    </div>
                    <div className="title absara">
                        Ingredientes
                    </div>
                    <div className="ingrediente ">
                        <div className="number absara">
                            1
                        </div>
                        <div className="text sneak-regular">  
                         Emb. de filetes de Bacalhau Ponto de Sal
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1 
                        </div>
                        <div className="text sneak-regular">  
                         Cebola
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1 
                        </div>
                        <div className="text sneak-regular">  
                         Dente de alho
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1 
                        </div>
                        <div className="text sneak-regular">  
                         Pacote pequeno de batata palha
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            20cl
                        </div>
                        <div className="text sneak-regular">  
                         Azeite
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            2 
                        </div>
                        <div className="text sneak-regular">  
                         Ovos
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            Q/B 
                        </div>
                        <div className="text sneak-regular">  
                         Azeitonas pretas
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            Q/B 
                        </div>
                        <div className="text sneak-regular">  
                        Salsa
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            Q/B 
                        </div>
                        <div className="text sneak-regular">  
                        Sal e pimenta
                        </div>
                        
                    </div>
                    
                    
                </div>
               
                </div>
            </div>
            <div className="right-col">

            <div className="card">
                    <div className="contentor">
                    <div className="foto">
                    {/* <img src={cardfoto} alt="Logo" /> */}

                    </div>
                    <div className="title absara">
                        Receita
                    </div>
                    <div className="ingrediente ">
                        <div className="number absara">
                            1 - 
                        </div>
                        <div className="text sneak-regular">  
                        Numa frigideira salteie em lume brando, a cebola às rodelas e os dentes de alho picados no azeite.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            2 - 
                        </div>
                        <div className="text sneak-regular">  
                        Adicione o bacalhau e as batatas-palha e misture bem. Deixe as batatas amolecerem um pouco.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            3 - 
                        </div>
                        <div className="text sneak-regular">  
                        Numa tigela, bata ligeiramente os ovos com um garfo.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            4 - 
                        </div>
                        <div className="text sneak-regular">  
                        Acrescente os ovos ao bacalhau e envolva até estar bem ligado e os ovos estarem cozinhados.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            5 - 
                        </div>
                        <div className="text sneak-regular">  
                        Prove e tempere a seu gosto. Sirva, salpicado com a salsa e as azeitonas.
                        </div>
                        
                    </div>
                  
                 
                    
                </div>
               </div>
            </div>
        </div>
    </div>
    <div id="section-nutri">
    <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                    <div className="icon-nutri">
                    <img src={iconnutri} alt="ingredient" />

                    </div>
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2">
                    <div className="titulo sneak-regular">
                            551
                        </div>
                        <div className="titulo sneak-regular">
                            32
                        </div>
                        <div className="titulo sneak-regular">
                           9
                        </div>
                        <div className="titulo sneak-regular">
                          23
                        </div>
                        <div className="titulo sneak-regular">
                         1
                        </div>
                        <div className="titulo sneak-regular">
                          43
                        </div>
                        <div className="titulo sneak-regular">
                         3
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
    {/* <div id="rec-section-3">
        <div className="wrapper">
            <div className="left-col">
            <img src={cardfoto1} alt="Logo" />

            </div>
            <div className="right-col">
                    <div className="wrapper sneak-regular">
                        <div className="conteudo">
                        <img className="img" src={recipeicon} alt="Logo" /><br></br>
                        Este vídeo exemplificativo contém todos os passos necessários para elaborar a receita utilizando as melhores técnicas.
                        </div>
                        
                    </div>
                </div>
        </div>
    </div> */}


  </Layout>
)


export default ReceitaA
